
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "ProductModification",
  });

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Товары",
        exact: true,
        to: "/manage/commodities",
      },
      {
        text: this.$route.params.productId,
        exact: true,
        to: `/manage/commodities/${this.$route.params.productId}/`,
      },
      {
        text: "Модификации",
        exact: true,
        to: `/manage/commodities/${this.$route.params.productId}/modifications`,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }

  //
}
